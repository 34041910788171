import { render, staticRenderFns } from "./FileCard.vue?vue&type=template&id=a5b15e16&scoped=true"
import script from "./FileCard.vue?vue&type=script&lang=js"
export * from "./FileCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5b15e16",
  null
  
)

export default component.exports